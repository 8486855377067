<template>
  <div class="total">
    <div>
      <el-button
        class="margin-tb-sm"
        type="primary"
        size="mini"
        @click="creatStudents"
        style="
          position: absolute;
          z-index: 999;
          top: 10px;
          right: 20px;
          background-color: #2fa4e7;
        "
        >创建学员</el-button
      >
      <div style="clear: both"></div>
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="花名册" name="two">
          <!-- <el-button class="margin-tb-sm">批量导入</el-button> -->
          <div class="padding-lr-lg">
            <div
              class="work-title-all flex justify-content-between aligin-items-center flex-wrap-wrap margin-tb-sm"
            >
              <div
                class="work-title-content flex aligin-items-center flex-wrap-wrap"
                style="width: 70%"
              >
                <el-popover width="150" trigger="click">
                  <el-checkbox-group
                    @change="student_status_change"
                    v-model="listQueryStudent2.student_status"
                  >
                    <el-checkbox
                      class="padding-xs"
                      v-for="(item, index) in studentStatus"
                      :key="index"
                      :label="item.Name"
                    />
                  </el-checkbox-group>
                  <div slot="reference" class="cursor">
                    <span>学员状态</span>
                    <i class="el-icon-caret-bottom" />
                    <span class="border-right" />
                  </div>
                </el-popover>

                <el-popover width="150" trigger="click">
                  <el-checkbox-group
                    @change="school_change"
                    v-model="school_name"
                  >
                    <el-checkbox
                      class="padding-xs"
                      :label="item.name"
                      v-for="(item, index) of OperatingCampus"
                      :key="index"
                    ></el-checkbox>
                  </el-checkbox-group>
                  <div slot="reference" class="cursor">
                    <span>报读校区</span>
                    <i class="el-icon-caret-bottom" />
                    <span class="border-right" />
                  </div>
                </el-popover>
                <el-popover width="250" trigger="hover">
                  <as-autocompelete
                    v-model="School_admin1"
                    :fetch-suggestions="querySearch"
                    :isInfinite="true"
                    keyName="name"
                    @selectChange="selectChange"
                    @loadMore="loadMoreStudent"
                    placeholder="请选择学管师"
                  >
                    <template slot="item" slot-scope="row">
                      <div
                        highlight-current-row
                        class="cursor Select_"
                        style="display: flex; width: 100%"
                      >
                        <div style="width: 50%">{{ row.item.full_name }}</div>
                        <div
                          style="font-size: 12px; width: 50%"
                          v-if="row.item.phone"
                        >
                          ({{ row.item.phone }})
                        </div>
                      </div>
                    </template>
                  </as-autocompelete>
                  <div slot="reference" class="cursor">
                    <span>学管师</span>
                    <i class="el-icon-caret-bottom" />
                  </div>
                </el-popover>

                <el-popover width="150" trigger="click">
                  <el-radio-group
                    @change="sex_change"
                    v-model="listQueryStudent2.sex"
                  >
                    <el-radio class="padding-xs" label="男" />
                    <el-radio class="padding-xs" label="女" />
                  </el-radio-group>
                  <div slot="reference" class="cursor">
                    <span>性别</span>
                    <i class="el-icon-caret-bottom" />
                    <span class="border-right" />
                  </div>
                </el-popover>

                <el-popover width="150" trigger="click">
                  <el-radio-group
                    @change="is_arrears_change"
                    v-model="listQueryStudent2.is_arrears"
                  >
                    <!-- <el-radio
                    class="padding-xs"
                    :label="item.value"
                    v-for="(item, index) of is_arrears"
                    :key="index"
                  > -->
                    <el-radio class="padding-xs" label="是" />
                    <el-radio class="padding-xs" label="否" />
                  </el-radio-group>
                  <div slot="reference" class="cursor">
                    <span>是否欠费</span>
                    <i class="el-icon-caret-bottom" />
                    <span class="border-right" />
                  </div>
                </el-popover>
                <el-popover width="150" trigger="click">
                  <el-radio-group
                    @change="has_oldstudent_change"
                    v-model="listQueryStudent2.has_oldstudent"
                  >
                    <!-- <el-radio class="padding-xs" :label="item.label"
										v-for="(item,index) of NewOldStudents " :key="index" /> -->
                    <el-radio class="padding-xs" label="新生" />
                    <el-radio class="padding-xs" label="老生" />
                  </el-radio-group>
                  <div slot="reference" class="cursor">
                    <span>新老生</span>
                    <i class="el-icon-caret-bottom" />
                    <span class="border-right" />
                  </div>
                </el-popover>

                <el-popover width="150" trigger="click" v-show="show4">
                  <el-radio-group
                    @change="has_balance_change"
                    v-model="listQueryStudent2.has_balance"
                  >
                    <el-radio class="padding-xs" label="有" />
                    <el-radio class="padding-xs" label="无" />
                  </el-radio-group>
                  <div slot="reference" class="cursor">
                    <span>余额</span>
                    <i class="el-icon-caret-bottom" />
                    <span class="border-right" />
                  </div>
                </el-popover>
                <el-popover width="150" trigger="click" v-show="show4">
                  <!-- <el-input v-model="channelInput" placeholder="" size="mini" /> -->
                  <el-checkbox-group
                    @change="Channel_change"
                    v-model="listQueryStudent2.source"
                  >
                    <el-checkbox
                      class="padding-xs"
                      :label="item.title"
                      v-for="(item, index) of Channel"
                      :key="index"
                    />
                  </el-checkbox-group>
                  <div slot="reference" class="cursor">
                    <span>渠道分类</span>
                    <i class="el-icon-caret-bottom" />
                    <span class="border-right" />
                  </div>
                </el-popover>

                <el-popover width="250" trigger="click" v-show="show4">
                  <!-- <el-input
                    v-model="publicSchoolInput"
                    placeholder=""
                    size="mini"
                  ></el-input> -->
                  <el-checkbox-group
                    @change="PublicSchools_change"
                    v-model="listQueryStudent2.public_schools"
                  >
                    <el-checkbox
                      class="padding-xs"
                      :label="item.title"
                      v-for="(item, index) of public_schools"
                      :key="index"
                    />
                  </el-checkbox-group>
                  <div slot="reference" class="cursor">
                    <span>公立学校</span>
                    <i class="el-icon-caret-bottom" />
                    <span class="border-right" />
                  </div>
                </el-popover>
                <el-popover width="150" trigger="click" v-show="show4">
                  <!-- <el-input
                    v-model="gradeInput"
                    placeholder=""
                    size="mini"
                  ></el-input> -->
                  <el-checkbox-group
                    @change="grade_change"
                    v-model="listQueryStudent2.grade"
                  >
                    <el-checkbox
                      class="padding-xs"
                      :label="item.title"
                      v-for="(item, index) of GradeList"
                      :key="index"
                    />
                  </el-checkbox-group>
                  <div slot="reference" class="cursor">
                    <span>年级</span>
                    <i class="el-icon-caret-bottom" />
                    <span class="border-right" />
                  </div>
                </el-popover>
              </div>

              <div class="work-title-search" style="width: 30%">
                <el-input
                  placeholder="姓名，手机号，微信号"
                  v-model="listQueryStudent2.KeyWord"
                  class="input-with-select"
                  size="mini"
                  style="width: 66%"
                >
                  <el-button
                    slot="append"
                    @click="select_Student"
                    icon="el-icon-search"
                  />
                </el-input>
                <span
                  class="text-blue margin-left-sm cursor"
                  @click="del_selsect"
                  >清空筛选</span
                >
              </div>
              <div></div>
            </div>

            <div
              class="work-title-status flex aligin-items-center justify-content-between flex-wrap-wrap margin-top-sm"
            >
              <div class="flex aligin-items-center flex-wrap-wrap">
                <div
                  v-if="listQueryStudent2.student_status.length"
                  class="text-gray flex aligin-items-center margin-left-lg margin-top-sm"
                >
                  <div>学员状态：</div>
                  <div
                    v-for="(btn, btnIndex) in listQueryStudent2.student_status"
                    :key="btnIndex"
                    :class="{ 'margin-left': btnIndex > 0 }"
                  >
                    <el-button size="mini">
                      {{ btn }}
                      <i
                        class="el-icon-close"
                        @click="delOderStatus(btnIndex)"
                      />
                    </el-button>
                  </div>
                </div>
                <div
                  v-if="School_admin.length"
                  class="text-gray flex aligin-items-center margin-left-lg margin-top-sm"
                >
                  <div>学管师：</div>
                  <div
                    v-for="(btn, btnIndex) in School_admin"
                    :key="btnIndex"
                    :class="{ 'margin-left': btnIndex > 0 }"
                  >
                    <el-button size="mini">
                      {{ btn }}
                      <i
                        class="el-icon-close"
                        @click="del_School_admin(btnIndex)"
                      />
                    </el-button>
                  </div>
                </div>
                <div
                  v-if="school_name.length"
                  class="text-gray flex aligin-items-center margin-left-lg margin-top-sm"
                >
                  <div>报读校区：</div>
                  <div
                    v-for="(btn, btnIndex) in school_name"
                    :key="btnIndex"
                    :class="{ 'margin-left': btnIndex > 0 }"
                  >
                    <el-button size="mini">
                      {{ btn }}
                      <i
                        class="el-icon-close"
                        @click="Status_school_id(btnIndex)"
                      />
                    </el-button>
                  </div>
                </div>

                <div
                  v-if="listQueryStudent2.sex.length"
                  class="text-gray flex aligin-items-center margin-left-lg margin-top-sm"
                >
                  <div>性别：</div>
                  <div
                    v-for="(btn, btnIndex) in listQueryStudent2.sex"
                    :key="btnIndex"
                    :class="{ 'margin-left': btnIndex > 0 }"
                  >
                    <el-button size="mini">
                      {{ btn }}
                      <i class="el-icon-close" @click="del_sex(btnIndex)" />
                    </el-button>
                  </div>
                </div>
                <div
                  v-if="listQueryStudent2.is_arrears.length"
                  class="text-gray flex aligin-items-center margin-left-lg margin-top-sm"
                >
                  <div>是否欠费：</div>
                  <div
                    v-for="(btn, btnIndex) in listQueryStudent2.is_arrears"
                    :key="btnIndex"
                    :class="{ 'margin-left': btnIndex > 0 }"
                  >
                    <el-button size="mini">
                      {{ btn }}
                      <i
                        class="el-icon-close"
                        @click="del_is_arrears(btnIndex)"
                      />
                    </el-button>
                  </div>
                </div>
                <div
                  v-if="listQueryStudent2.has_oldstudent.length"
                  class="text-gray flex aligin-items-center margin-left-lg margin-top-sm"
                >
                  <div>新老生：</div>
                  <div :class="{ 'margin-left': btnIndex > 0 }">
                    <el-button size="mini">
                      {{ listQueryStudent2.has_oldstudent }}
                      <i
                        class="el-icon-close"
                        @click="del_has_oldstudent(btnIndex)"
                      />
                    </el-button>
                  </div>
                </div>
                <div
                  v-if="listQueryStudent2.has_balance.length"
                  class="text-gray flex aligin-items-center margin-left-lg margin-top-sm"
                >
                  <div>余额：</div>
                  <div :class="{ 'margin-left': btnIndex > 0 }">
                    <el-button size="mini">
                      {{ listQueryStudent2.has_balance }}
                      <i
                        class="el-icon-close"
                        @click="del_has_balance(btnIndex)"
                      />
                    </el-button>
                  </div>
                </div>
                <div
                  v-if="listQueryStudent2.source.length"
                  class="text-gray flex aligin-items-center margin-left-lg margin-top-sm"
                >
                  <div>渠道分类：</div>
                  <div
                    v-for="(btn, btnIndex) in listQueryStudent2.source"
                    :key="btnIndex"
                    :class="{ 'margin-left': btnIndex > 0 }"
                  >
                    <el-button size="mini">
                      {{ btn }}
                      <i class="el-icon-close" @click="del_Channel(btnIndex)" />
                    </el-button>
                  </div>
                </div>
                <div
                  v-if="listQueryStudent2.public_schools.length"
                  class="text-gray flex aligin-items-center margin-left-lg margin-top-sm"
                >
                  <div>公立学校：</div>
                  <div
                    v-for="(btn, btnIndex) in listQueryStudent2.public_schools"
                    :key="btnIndex"
                    :class="{ 'margin-left': btnIndex > 0 }"
                  >
                    <el-button size="mini">
                      {{ btn }}
                      <i
                        class="el-icon-close"
                        @click="del_PublicSchools(btnIndex)"
                      />
                    </el-button>
                  </div>
                </div>
                <div
                  v-if="listQueryStudent2.grade.length"
                  class="text-gray flex aligin-items-center margin-left-lg margin-top-sm"
                >
                  <div>年级：</div>
                  <div
                    v-for="(btn, btnIndex) in listQueryStudent2.grade"
                    :key="btnIndex"
                    :class="{ 'margin-left': btnIndex > 0 }"
                  >
                    <el-button size="mini">
                      {{ btn }}
                      <i class="el-icon-close" @click="del_Grade(btnIndex)" />
                    </el-button>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex flex-wrap-wrap margin-top-xl">
              <!-- <el-button> 导出当前结果</el-button> -->
              <!-- <el-button> 发送通知公告</el-button> -->
              <!-- <el-button @click="editHandle(3)">批量调整</el-button> -->
              <!-- <el-button> 批量打印二维码</el-button> -->
            </div>
            <div
              class="myClient-text flex justify-content-between myClient-top-10"
            >
              <div>
                当前结果：学员共计{{ rosterSize }}名，欠费共计{{
                  arrearMoney
                }}元
              </div>
              <el-popover width="200" trigger="click">
                <el-checkbox-group
                  v-model="columnslist"
                  v-for="(item, index) in columns"
                  :key="index"
                >
                  <el-checkbox class="padding-xs" :label="item.key">{{
                    item.name
                  }}</el-checkbox>
                </el-checkbox-group>
                <!-- <div class="cursor" slot="reference">
                  <span class="displayColumns">自定义显示列</span>
                </div> -->
                <div slot="reference">
                  <el-button type="primary" size="mini">自定义显示列</el-button>
                </div>
              </el-popover>
            </div>

            <div style="margin-top: 10px">
              <as-list
                ref="studentListAsList"
                :ajaxMethods="{ list: 'StudentList' }"
                :listQueryProp="listQueryStudent"
                :isChoice="true"
                @SelectionChange="selectAllChange"
                @requestChange="requestChange"
              >
                <el-table-column type="selection" height="40" width="55">
                </el-table-column>
                <el-table-column
                  align="left"
                  fixed="left"
                  height="40"
                  width="150"
                  sortable
                  label="学员名字"
                >
                  <template slot-scope="scope">
                    <router-link
                      replace
                      :to="{
                        path: '/educationCenter/studentInformation',
                        query: { id: scope.row.id },
                      }"
                    >
                      <span class="cursor text-blue">
                        {{ scope.row.FullName }}</span
                      >
                    </router-link>
                  </template>
                </el-table-column>
                <el-table-column
                  height="40"
                  width="60"
                  align="center"
                  fixed="left"
                  prop="sex"
                  label="性别"
                >
                  <template slot-scope="scope">
                    <span v-if="scope.row.sex == 1">男</span>
                    <span v-if="scope.row.sex == 0">女</span>
                    <span v-if="scope.row.sex == 2">未知</span>
                  </template>
                </el-table-column>
                <el-table-column
                  height="40"
                  width="60"
                  align="center"
                  prop="relationship"
                  fixed="left"
                  label="关系"
                >
                  <template slot-scope="scope">
                    <span>{{ scope.row.relation_name }}</span>
                  </template>
                </el-table-column>

                <!-- <el-table-column
                  v-for="item in columnslistComputed"
                  :key="item.key"
                  height="40"
                  align="center"
                  :prop="item.key"
                  :label="item.name"
                ></el-table-column> -->
                <el-table-column
                  align="center"
                  width="120"
                  fixed="left"
                  label="生日"
                >
                  <template slot-scope="scope">
                    <span v-if="scope.row.birthday">
                      {{ scope.row.birthday.split("T")[0] }}</span
                    >
                  </template>
                </el-table-column>
				<!-- 学员类型 -->
				<el-table-column
				  height="40"
				  width="100"
				  align="center"
				  fixed="left"
				  prop="sex"
				  label="学员类型"
				>
				  <template slot-scope="scope">
				    <span>{{scope.row.student_typename}}</span>
				   
				  </template>
				</el-table-column>
				<el-table-column
				  height="40"
				  width="100"
				  align="center"
				  fixed="left"
				  prop="sex"
				  label="转正式学员"
				>
				  <template slot-scope="scope">
				    <el-switch
					 @change="changetypeint($event,0,scope.row.id)"
				      v-model="typeint"
					  v-if="scope.row.type==1"
				      active-color="#13ce66"
				      inactive-color="#EBEEF5">
					 
				    </el-switch>
				  </template>
				</el-table-column>
				
				

                <template v-for="(item, index) in columnslistComputed">
                  <el-table-column
                    v-if="['balance'].includes(item.key)"
                    align="center"
                    :key="index"
                    sortable
                    height="40"
                    :prop="item.key"
                    :label="item.name"
                  >
                  </el-table-column>
                  <el-table-column
                    v-else
                    align="center"
                    :key="index"
                    height="40"
                    :prop="item.key"
                    :label="item.name"
                  >
                  </el-table-column>
                </template>
				
				
				
                <el-table-column
                  v-if="
                    $store.getters.getUserInfo.post_type == 4022 ||
                    $store.getters.getUserInfo.post_type == 2016
                  "
                  align="left"
                  label="操作"
                >
                  <template slot-scope="scope">
                    <span
                      class="cursor text-blue"
                      @click="getxueguan(scope.row, true)"
                      >分配学管师</span
                    >
                  </template>
                </el-table-column>
              </as-list>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="报读列表" name="three">
          <StudentComponents></StudentComponents>
        </el-tab-pane>

        <el-tab-pane label="分班列表" name="fore">
          <DivisionComponents></DivisionComponents>
        </el-tab-pane>
        <!-- 
        <el-tab-pane label="学员成绩" height="40" name="fives">
          <StudentAchievement></StudentAchievement>
        </el-tab-pane> -->
      </el-tabs>
    </div>
    <NewProject
      :Visible="editVisible && editType === 1"
      @closepop="closeEditPop"
    ></NewProject>
    <newExam
      :Visible="editVisible && editType === 2"
      @closepop="closeEditPop"
    ></newExam>
    <BatchAdjustment
      ref="BatchAdjustmentList"
      :Adjustment="BatchAdjustmentList"
      :Visible="editVisible && editType === 3"
      @closepop="closeEditPop"
    ></BatchAdjustment>
    <el-dialog
      title="分配学管师"
      :visible.sync="Schooladmin_dag"
      :append-to-body="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="flex aligin-items-center justify-content-between padding-sm">
        <div>
          <div>
            <span>当前学管师：</span
            ><span v-if="Schooladmin_current">{{
              Schooladmin_current_name
            }}</span>
          </div>
          <div>
            <span>选择的学管师：</span
            ><span v-if="selectedSchooladmin">{{
              selectedSchooladmin.full_name
            }}</span>
          </div>
        </div>
        <div>
          <el-input
            size="medium"
            placeholder="请输入学管师关键字搜索"
            suffix-icon="el-icon-search"
            v-model="Schooladmin_keyword"
            style="width: 230px"
          ></el-input>
          <el-button
            style="margin-left: 15px"
            type="primary"
            size="small "
            @click="getxg()"
            >搜索</el-button
          >
        </div>
      </div>

      <div>
        <el-table
          :data="Schooladmin"
          highlight-current-row
          @current-change="handleSelectionChange"
          height="400"
        >
          <el-table-column
            property="full_name"
            label="学管师"
            align="center"
          ></el-table-column>
          <el-table-column property="sex" label="性别" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.sex == 1">男</span>
              <span v-if="scope.row.sex == 0">女</span>
              <span v-if="scope.row.sex == 2">未知</span>
            </template>
          </el-table-column>
		  
		 
		  
		  
          <el-table-column property="phone" label="手机号" align="center">
          </el-table-column>
        </el-table>
        <el-pagination
          style="margin-top: 20px; text-align: center"
          background
          layout="prev, pager, next,total"
          :total="Schooladmin_total"
          @current-change="pageChange"
        ></el-pagination>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button @click="getxueguan()">取 消</el-button>
        <el-button type="primary" @click="determine_xueguan()">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import AsList from "@/components/as-list";

import NewProject from "../components/newProject";
import StudentComponents from "./TraineeComponents/StudentComponents";
import DivisionComponents from "./TraineeComponents/DivisionComponents";
import StudentAchievement from "./TraineeComponents/StudentAchievement";
import BatchAdjustment from "../components/BatchAdjustment";
import newExam from "../components/newExam";
import { course, starList, withWithout } from "@/config/index";
export default {
  components: {
    AsList,
    NewProject,
    newExam,
    BatchAdjustment,
    StudentComponents,
    DivisionComponents,
    StudentAchievement,
  },
  props: {
    Visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      Schooladmin_current_name: "",
		typeint:0,
      Schooladmin_current: {},
      Schooladmin_PageIndex: 1,
      Schooladmin_PageSize: 10,
      Schooladmin_keyword: "",
      Schooladmin_total: 0,
      selectedSchooladmin: {},
      Schooladmin_dag: false,
      school_name: [],
      offsetHeight: 0,
      editVisible: false,
      editType: 0,
      BatchAdjustmentList: [
        { name: "关系", key: "school_admin_name" },
        { name: "联系方式", key: "school_admin_name" },
        { name: "学管师", key: "school_admin_name" },
        { name: "报读课程", key: "school_admin_name" },
        { name: "授课模式", key: "school_admin_name" },
        { name: "购买数量", key: "school_admin_name" },
        { name: "已用数量", key: "school_admin_name" },
        { name: "剩余数量", key: "school_admin_name" },
        { name: "总学费", key: "school_admin_name" },
        { name: "实缴费用", key: "school_admin_name" },
        { name: "已用费用", key: "school_admin_name" },
        { name: "报读学校", key: "school_admin_name" },
        { name: "状态", key: "school_admin_name" },
      ],
      //报读列表
      EnrollmentList: [],
      CourseClass: [], //课程大类
      ApplyForCampus: [], //报读校区
      EnrollmentStatus: [], //学员报读状态
      School_admin_id_Enrolling: [], //学管师——id_报读
      School_admin_Enrolling: [], //学管师——报读
      getIntroducer_PageIndex_Enrolling: 1, //学管师-分页
      //报读列表结束

      //花名册
      columnslist: [
        "phone",
        "balance",
        "nation_name",
        "grade_name",
        "school_admin_name",
        "source",
        "id_card",
      ],
      columns: [
        { name: "联系方式", key: "phone" },
        { name: "余额", key: "balance" },
        { name: "微信号", key: "wechat_no" },
        { name: "民族", key: "nation_name" },
        { name: "年级", key: "grade_name" },
        { name: "高中学校", key: "high_school" },
        { name: "小学学校", key: "primary_school" },
        { name: "行业", key: "industry" },
        { name: "学管师", key: "school_admin_name" },
        { name: "在读课程数", key: "read_count" },
        { name: "渠道名称", key: "source" },
        { name: "身份证号", key: "id_card" },
        { name: "公立学校", key: "public_schools" },
        { name: "公立学校班级", key: "public_grade" },
        { name: "初中学校", key: "junior_high_school" },
        { name: "幼儿园", key: "kindergarten" },
        { name: "单位名称", key: "company_name" },
		
      ],
      arrearMoney: 0, //欠费
      rosterSize: 0,
      rosterList: [], //花名册列表
      studentStatus: [
        { Key: 0, Desc: "未知", Name: "未知" },
        { Key: 1, Desc: "在读", Name: "在读" },
        { Key: 2, Desc: "停课", Name: "停课" },
        { Key: 3, Desc: "结课", Name: "结课" },
      ], //学员状态
      School_admin1: "",
      GradeList: [], //年级
      public_schools: [], //公立学校
      Channel: [], //来源渠道
      stuSelect: "", //搜索类型
      PreSearch: "",
      relationship: ["母亲", "父亲", "本人", "其他"],
      getIntroducer_PageIndex: 1,
      getIntroducer_PageSize: 10,
      school_adminList: [],
      School_admin: [],
      School_admin_id: [],
      listQuery: {
        HasRole: false,
        module: "",
        TotalCount: 0,
        PageIndex: 1,
        PageSize: 10,
      },
      listQueryStudent: {
        grade: [], //年级
        public_schools: [], //公立学校
        source: [], //来源渠道分类
        student_status: [], //学员状态
        school_id: [], //报读校区id
        wechat_no: "", //微信号
        school_admin: [], //学管师
        sex: -1, //1=男
        is_arrears: null, //是否欠费
        has_oldstudent: null, //是否老生
        has_balance: null, //是否有余额
        outer_school_name: "", //就读学校名称
        outer_class: "", //就读学校班级
        phone: "", //手机号
        business_type: 0, //业务类型 筛选学员0 = 未知 (未知), 1 = 学费退费 (学费退费), 2 = 余额退费 (余额退费), 3 = 教杂费退费 (教杂费退费) = ['0', '1', '2', '3'],
        username: "", //学员姓名（z账号）
        TotalCount: 0, //总数
        PageIndex: 1,
        PageSize: 13,
        KeyWord: "",
      },
      listQueryStudent2: {
        grade: [], //年级
        public_schools: [], //公立学校
        source: [], //来源渠道分类
        student_status: [], //学员状态
        school_id: [], //报读校区id
        wechat_no: "string", //微信号
        school_admin: [], //学管师
        sex: -1, //1=男
        is_arrears: true, //是否欠费
        has_oldstudent: true, //是否老生
        has_balance: true, //是否有余额
        outer_school_name: "string", //就读学校名称
        outer_class: "string", //就读学校班级
        phone: "string", //手机号
        business_type: 0, //业务类型 筛选学员0 = 未知 (未知), 1 = 学费退费 (学费退费), 2 = 余额退费 (余额退费), 3 = 教杂费退费 (教杂费退费) = ['0', '1', '2', '3'],
        username: "string", //学员姓名（z账号）
        TotalCount: 0, //总数
        pageIndex: 1,
        pageSize: 15,
        KeyWord: "",
      },
      //花名册结束
      course,
      starList,
      withWithout,
      editType: 1,
      isShow: false,
      show4: true,
      show5: false,
      color1: true,
      color2: false,
      color3: false,
      num: 1,

      schoolInput: "",
      date: "",
      OperatingCampus: [], //经办校区
      OperatingCampusList: [], //选择的经办校区
      attention: "", //是否关注家校中心
      magneticCard: "", //是否绑定磁卡
      humanFace: "", //是否录入人脸
      Arrears: "", //是否欠费
      NewOldStudents: "", //新老生
      schoolList: "",
      schoolManagerList: [],
      schoolManager: "",
      Star: "", //星标
      balance: "", //余额
      star: [], //星级
      stuInput: "", // 学生姓名查询
      stuSelect: "",
      birthday: "", //生日月份
      channelInput: "", //渠道分类输入
      channelLists: "", //渠道名称orderTypeList
      orderSatus: ["待支付", "已完成"], // 订单状态
      monthMin: "", //筛选的最小年龄
      monthMax: "", //筛选的最大年龄
      publicSchoolInput: "", //搜索学校
      publicSchoolList: [], //公立学校
      gradeInput: "",
      gradeList: [],
      activeName: "two",
      Schooladmin: [],
      monthList: [],
      total: "",
      datalist: [],
      ProjectList: [
        {
          ProjectName: "xxx", //项目名称
        },
      ], //项目名称

      multipleSelection: [],
    };
  },
  computed: {
    columnslistComputed() {
      return this.columns.filter(
        (t) => this.columnslist.findIndex((t1) => t1 == t.key) > -1
      );
    },
  },
  created() {
    //  var main = document.getElementById('main');
    // var a=100vh

    var main = document.getElementById("main");
    // var o = document.getElementById("view");
    // this.offsetHeight = main.style.height;
    // this.getStudentStatus();
    this.getOperatingCampus();
    this.get_StudentStatus();
    // this.monthList
    for (let index = 1; index < 36; index++) {
      this.monthList.push(index + "个月");
    }
    for (let index = 3; index < 51; index++) {
      this.monthList.push(index + "岁");
    }
    // this.getStudentList();
  },
  methods: {
	  //修改学员类型
	  changetypeint:function($event,num,id){
		  
		  console.log($event,num,id);
		  if($event==true){
			  this.API.ChangeTypes(id,num).then((res)=>{
				  if(res.success){
					  this.$message({
					    message: res.message,
					    
					  });
				  }
				  this.getStudentList();
			  })
		  }
	  },
    determine_xueguan() {
      if (this.selectedSchooladmin && this.selectedSchooladmin.id) {
        this.Schooladmin_current.school_admin = this.selectedSchooladmin.id;
        this.API.StudentCreate(this.Schooladmin_current).then((res) => {
          if (res.success) {
            this.Schooladmin_dag = false;
            this.listQueryStudent.KeyWord = "1";
            this.listQueryStudent.KeyWord = "";
            this.$message({
              message: res.message,
              type: "success",
            });
          } else {
            this.$message.error(res.message);
          }
        });
      } else {
        this.$message.error("请选择学管师");
      }
    },
    pageChange(pageindex) {
      this.Schooladmin_PageIndex = pageindex;
      this.getxueguan();
    },
    handleSelectionChange(val) {
      this.selectedSchooladmin = val;
    },
    getxg() {
      var model = {
        // AccountType: [5],
        post_tag: ["学管"],
        PageIndex: this.Schooladmin_PageIndex,
        PageSize: this.Schooladmin_PageSize,
        KeyWord: this.Schooladmin_keyword,
      };
      this.API.accountQuery(model).then((res) => {
        this.Schooladmin = res.data.rows || [];
        this.Schooladmin_total = res.data.total;
      });
    },
    getxueguan(data, t) {
      if (t) {
        this.Schooladmin_current_name = data.school_admin_name;
        this.Schooladmin_current = data;
        this.Schooladmin_dag = true;
        this.getxg();
      } else {
        this.Schooladmin_current = "";
        this.Schooladmin_dag = false;
      }
    },
    //花名册
    //接口请求返回
    requestChange(res) {
      this.rosterSize = res.total;
      this.arrearMoney = res.outer_data.arrearMoney;
    },
    //获取选中内容
    selectAllChange(val) {
      this.BatchAdjustmentList = val;
      this.$refs.BatchAdjustmentList.refsh(this.BatchAdjustmentList);
    },
    //学生状态

    student_status_change() {
      var studentStatus = this.studentStatus.filter((rs) => {
        return this.listQueryStudent2.student_status.indexOf(rs.Name) != -1;
      });
      this.listQueryStudent.student_status = studentStatus.map((rs) => {
        return rs.Key;
      });
    },
    //校区
    school_change(e) {
      var school_id = this.OperatingCampus.filter((rs) => {
        return this.school_name.indexOf(rs.name) != -1;
      });
      this.listQueryStudent.school_id = school_id.map((rs) => {
        return rs.id;
      });
    },
    //性别
    sex_change() {
      if (this.listQueryStudent2.sex == "男") {
        this.listQueryStudent.sex = 1;
      } else if (this.listQueryStudent2.sex == "女") {
        this.listQueryStudent.sex = 0;
      } else {
        this.listQueryStudent.sex = -1;
      }
      // GetSettings;
    },
    //是否欠费
    is_arrears_change() {
      if (this.listQueryStudent2.is_arrears == "是") {
        this.listQueryStudent.is_arrears = true;
      } else if (this.listQueryStudent2.is_arrears == "否") {
        this.listQueryStudent.is_arrears = false;
      } else {
        this.listQueryStudent.is_arrears = null;
      }
      // GetSettings;
    },
    //新老生
    has_oldstudent_change() {
      if (this.listQueryStudent2.has_oldstudent == "新生") {
        this.listQueryStudent.has_oldstudent = true;
      } else if (this.listQueryStudent2.has_oldstudent == "老生") {
        this.listQueryStudent.has_oldstudent = false;
      } else {
        this.listQueryStudent.has_oldstudent = null;
      }
    },
    //余额
    has_balance_change() {
      if (this.listQueryStudent2.has_balance == "有") {
        this.listQueryStudent.has_balance = true;
      } else if (this.listQueryStudent2.has_balance == "无") {
        this.listQueryStudent.has_balance = false;
      } else {
        this.listQueryStudent.has_balance = null;
      }
    },
    //渠道分类
    Channel_change() {
      this.listQueryStudent.source = this.listQueryStudent2.source;
    },
    //公立学校
    PublicSchools_change() {
      this.listQueryStudent.public_schools = this.listQueryStudent2.public_schools;
      // listQueryStudent2.PublicSchools
      // var public_schools = this.public_schools.filter((rs) => {
      //   return this.listQueryStudent2.public_schools.indexOf(rs.title) != -1;
      // });
      // this.listQueryStudent.public_schools = public_schools.map((rs) => {
      //   return rs.id;
      // });
    },
    //年级
    grade_change() {
      var grade = this.GradeList.filter((rs) => {
        return this.listQueryStudent2.grade.indexOf(rs.title) != -1;
      });
      this.listQueryStudent.grade = grade.map((rs) => {
        return rs.id;
      });
    },
    get_StudentStatus() {
      this.API.SystemGetSettings("StudentStatus", false).then((res) => {});
    },
    //删除学管师
    del_School_admin(index) {
      this.School_admin.splice(index, 1);
      this.School_admin_id.splice(index, 1);
      this.listQueryStudent.school_admin = this.School_admin_id;
    },
    //删除公立学校
    del_PublicSchools(index) {
      this.listQueryStudent2.public_schools.splice(index, 1);
      this.listQueryStudent.public_schools = this.listQueryStudent2.public_schools;
    },

    //删除年级
    del_Grade(index) {
      this.listQueryStudent2.grade.splice(index, 1);
      var grade = this.GradeList.filter((rs) => {
        return this.listQueryStudent2.grade.indexOf(rs.title) != -1;
      });
      this.listQueryStudent.grade = grade.map((rs) => {
        return rs.id;
      });
    },
    //删除余额
    del_has_balance() {
      this.listQueryStudent2.has_balance = "";
      this.listQueryStudent.has_balance = null;
    },
    //删除新老生
    del_has_oldstudent() {
      this.listQueryStudent2.has_oldstudent = "";
      this.listQueryStudent.has_oldstudent = null;
    },
    //删除性别
    del_sex() {
      this.listQueryStudent2.sex = "";
      this.listQueryStudent.sex = -1;
    },
    //删除是否欠费
    del_is_arrears(index) {
      this.listQueryStudent2.is_arrears = "";
      this.listQueryStudent.is_arrears = null;
    },
    //删除学员状态
    delOderStatus(index) {
      this.listQueryStudent2.student_status.splice(index, 1);
      this.listQueryStudent.student_status.splice(index, 1);
    },
    //删除经办校区
    Status_school_id(index) {
      //清除校区
      this.school_name.splice(index, 1);
      var school_id = this.OperatingCampus.filter((rs) => {
        return this.school_name.indexOf(rs.name) != -1;
      });
      this.listQueryStudent.school_id = school_id.map((rs) => {
        return rs.id;
      });
    },
    //删除渠道分类
    del_Channel(index) {
      this.listQueryStudent2.source.splice(index, 1);
      this.listQueryStudent.source = this.listQueryStudent2.source;
    },
    //搜索学员
    select_Student() {
      this.listQueryStudent.KeyWord = this.listQueryStudent2.KeyWord;
    },
    getIntroducer(keyword) {
      var model = {
        // AccountType: [5],
        post_tag: ["学管"],
        PageIndex: this.getIntroducer_PageIndex,
        PageSize: this.getIntroducer_PageSize,
        KeyWord: keyword,
      };
      return this.API.accountQuery(model);
    },
    //点击某一项的事件 学管师
    selectChange(item) {
      var _true = false;
      if (this.School_admin_id && this.School_admin_id.length) {
        for (let index = 0; index < this.School_admin_id.length; index++) {
          if (this.School_admin_id[index] == item.id) {
            return (_true = true);
          } else {
            _true = false;
          }
        }
        if (!_true) {
          this.School_admin.push(item.full_name);
          this.School_admin_id.push(item.id);
          this.listQueryStudent.school_admin = this.School_admin_id;
        }
      } else {
        this.School_admin.push(item.full_name);
        this.School_admin_id.push(item.id);
        this.listQueryStudent.school_admin = this.School_admin_id;
      }
    },
    //清空筛选
    del_selsect() {
      this.listQueryStudent2 = this.istQueryStudent2 = {
        grade: [], //年级
        public_schools: [], //公立学校
        source: [], //来源渠道分类
        student_status: [], //学员状态
        school_id: [], //报读校区id
        wechat_no: "", //微信号
        school_admin: [], //学管师
        sex: -1, //1=男
        is_arrears: true, //是否欠费
        has_oldstudent: true, //是否老生
        has_balance: true, //是否有余额
        outer_school_name: "", //就读学校名称
        outer_class: "", //就读学校班级
        phone: "", //手机号
        business_type: 0, //业务类型 筛选学员0 = 未知 (未知), 1 = 学费退费 (学费退费), 2 = 余额退费 (余额退费), 3 = 教杂费退费 (教杂费退费) = ['0', '1', '2', '3'],
        username: "", //学员姓名（z账号）
        TotalCount: 0, //总数
        PageIndex: 1,
        PageSize: 10,
        KeyWord: "",
      };
      this.listQueryStudent = this.listQueryStudent = {
        grade: [], //年级
        public_schools: [], //公立学校
        source: [], //来源渠道分类
        student_status: [], //学员状态
        school_id: [], //报读校区id
        wechat_no: "", //微信号
        school_admin: [], //学管师
        sex: -1, //1=男
        is_arrears: null, //是否欠费
        has_oldstudent: null, //是否老生
        has_balance: null, //是否有余额
        outer_school_name: "", //就读学校名称
        outer_class: "", //就读学校班级
        phone: "", //手机号
        business_type: 0, //业务类型 筛选学员0 = 未知 (未知), 1 = 学费退费 (学费退费), 2 = 余额退费 (余额退费), 3 = 教杂费退费 (教杂费退费) = ['0', '1', '2', '3'],
        username: "", //学员姓名（z账号）
        TotalCount: 0, //总数
        pageIndex: 1,
        pageSize: 15,
        KeyWord: "",
      };
      //清空学管师
      this.School_admin = [];
      this.School_admin_id = [];
      this.listQueryStudent.school_admin = this.School_admin_id;
    },
    //搜索事件
    querySearch(queryString, cb) {
      this.getIntroducer_PageIndex = 1;
      this.getIntroducer(queryString).then((res) => {
        let list = res.data.rows;

        cb(res.data.rows || []);
      });
    },
    loadMoreStudent(queryString, cb) {
      this.getIntroducer_PageIndex++;
      this.getIntroducer(queryString).then((res) => {
        let list = res.data.rows;

        cb(list);
      });
    },
    creatStudents() {
      var studentEdit = {
        title: "添加学员",
        Edit: false,
      };
      this.$Dialog.CreatStudents({ studentEdit }).then((res) => {
        this.$refs.studentListAsList.reload();
      });
    },
    //获取学员状态数据字典
    getStudentStatus() {
      this.listQuery.module = "studentStatus";
      this.$Dictionary.GetDictionarys(this.listQuery).then((res) => {
        this.studentStatus = res.data.rows;
        this.studentStatus = this.studentStatus.filter((x) => {
          return x.parent_id != 0;
        });
      });
    },
    getOperatingCampus() {
      // this.listQuery.module = "OperatingCampus";
      // this.$Dictionary.GetDictionarys(this.listQuery).then((res) => {
      //   this.OperatingCampus = res.data.rows;
      //   this.OperatingCampus = this.OperatingCampus.filter((x) => {
      //     return x.parent_id != 0;
      //   });
      // });
      //查询校区
      var model = {
        type: 1,
        PageIndex: 1,
        PageSize: 10000,
        Sort: [
          {
            Field: "id",
            Type: 0,
          },
        ],
      };
      this.API.OrganizationList(model).then((res) => {
        this.OperatingCampus = res.data.rows;
      });
      this.listQuery.module = "Channel";
      this.$Dictionary.GetDictionarys(this.listQuery).then((res) => {
        this.Channel = res.data.rows;
        this.Channel = this.Channel.filter((x) => {
          return x.parent_id != 0;
        });
      });
      this.listQuery.module = "PublicSchools";
      this.$Dictionary.GetDictionarys(this.listQuery).then((res) => {
        this.public_schools = res.data.rows;
        this.public_schools = this.public_schools.filter((x) => {
          return x.parent_id != 0;
        });
      });
      this.listQuery.module = "Grade";
      this.$Dictionary.GetDictionarys(this.listQuery).then((res) => {
        this.GradeList = res.data.rows;
        // this.GradeList = this.GradeList.filter((x) => {
        //   return x.parent_id != 0;
        // });
      });
    },

    //花名册结束
    handleCurrentChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getStudentList();
    },
    getStudentList() {
      var model = {
        KeyWord: this.KeyWord,
        PageIndex: this.pageIndex,
        PageSize: this.pageSize,
      };
      this.API.StudentList(model).then((res) => {
        if (res.success) {
          this.total = res.data.total;
          this.datalist = res.data.rows;
        }
      });
    },

    JumpUrl(item) {
      this.$router.push("/educationCenter/studentInformation?id=" + item.id);
    },
    starStatus(index) {
      this.starS.splice(index, 1);
    },

    openDate(t) {
      if (t) {
        this.$refs.evaluation.pickerVisible = true;
      } else {
        this.$refs.datePicker.pickerVisible = true;
      }
    },
    classopenDate(t) {
      this.$refs.classdatePicker.pickerVisible = true;
    },
    handleClick(row) {},
    editHandle(type) {
      if (type == 3) {
        if (this.BatchAdjustmentList && this.BatchAdjustmentList.length) {
          this.editVisible = true;
          this.editType = type;
        } else {
          this.$message.error("请先勾选需要调整的学员！");
        }
      } else {
        this.editVisible = true;
        this.editType = type;
      }
    },
    handleChange(value) {},
    goNav(url) {
      this.$router.push(url);
    },
    colorChange(type) {
      if (type == 1) {
        this.color1 = true;
        this.color2 = false;
        this.color3 = false;
      } else if (type == 2) {
        this.color2 = true;
        this.color1 = false;
        this.color3 = false;
      } else if (type == 3) {
        this.color2 = false;
        this.color1 = false;
        this.color3 = true;
      }
    },
  },
};
</script>

<style  scoped>
>>> el-table-column {
  height: 40px;
}
.color-box {
  color: #0081ff;
  border: 1px solid #8cc5ff;
  background-color: #e8f5ff;
}
.work-title-content div {
  padding: 0 5px 0 0px;
}
.total {
  width: 100%;
}

.shool {
  margin: 0px 10px;
}

>>> .is-active {
  height: 43px;
  font-size: 17px;
  font-weight: bold !important;
}
.el-select .el-input {
  width: 105px;
}
>>> .el-table__body tr.current-row > td {
  background-color: #2fa4e7 !important;
  color: #fff;
}
.class-tag {
  border-radius: 4px;
  border: 1px solid #4a9efc;
  padding: 2px 4px;
  background-color: #dbebfe;
  color: #4a9efc;
}
</style>
