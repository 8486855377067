<template>
  <div class="total padding-lr-lg">
    <div>
      <div class="work-title bg-white">
        <div
          class="work-title-all flex justify-content-between aligin-items-center flex-wrap-wrap"
        >
          <div
            class="work-title-content flex aligin-items-center flex-wrap-wrap"
            style="width: 60%"
          >
            <el-popover width="150" trigger="click">
              <!-- <el-input
                v-model="school_name1"
                placeholder=""
                size="mini"
              ></el-input> -->
              <el-checkbox-group @change="school_change" v-model="school_name">
                <el-checkbox
                  class="padding-xs"
                  :label="item.name"
                  v-for="(item, index) of school_list"
                  :key="index"
                ></el-checkbox>
              </el-checkbox-group>
              <div slot="reference" class="cursor">
                <span>报读校区</span>
                <i class="el-icon-caret-bottom" />
                <span class="border-right" />
              </div>
            </el-popover>
            <el-popover width="250" trigger="click">
              <!-- <el-input
                v-model="schoolInput"
                placeholder=""
                size="mini"
              ></el-input> -->
              <el-checkbox-group
                @change="courseClass_change"
                v-model="courseClass_name"
              >
                <el-checkbox
                  class="padding-xs"
                  :label="item.title"
                  v-for="(item, index) of courseClassList"
                  :key="index"
                />
              </el-checkbox-group>
              <div slot="reference" class="cursor">
                <span>课程大类</span>
                <i class="el-icon-caret-bottom" />
              </div>
            </el-popover>
            <div v-if="!query.curr_cate_type.length" slot="reference">
              <i class="el-icon-link text-grey" />
              <span class="text-grey">课程</span>
              <i class="el-icon-caret-bottom text-grey" />
            </div>
            <el-popover v-else width="350" trigger="click">
              <!-- <el-input v-model="classInput" placeholder="" size="mini" /> -->
              <el-checkbox-group
                @change="Curriculum_change"
                v-model="Curriculum_name"
              >
                <el-checkbox
                  class="padding-xs"
                  :label="item.curriculum_name"
                  v-for="(item, index) of CurriculumList"
                  :key="index"
                />
              </el-checkbox-group>
              <div slot="reference" class="cursor">
                <i class="el-icon-link text-grey" />
                <span>课程</span>
                <i class="el-icon-caret-bottom" />
              </div>
            </el-popover>
            <el-popover width="250" trigger="hover">
              <as-autocompelete
                v-model="School_admin1"
                :fetch-suggestions="querySearch"
                :isInfinite="true"
                keyName="name"
                @selectChange="selectChange_Enrolling"
                @loadMore="loadMoreStudent_Enrolling"
                placeholder="请选择学管师"
              >
                <!-- <div slot="title">这里是示例111标题</div> -->
                <template slot="item" slot-scope="row">
                  <div
                    highlight-current-row
                    class="cursor Select_"
                    style="display: flex; width: 100%"
                  >
                    <div style="width: 50%">{{ row.item.full_name }}</div>
                    <div
                      style="font-size: 12px; width: 50%"
                      v-if="row.item.phone"
                    >
                      ({{ row.item.phone }})
                    </div>
                  </div>
                </template>
              </as-autocompelete>
              <div slot="reference" class="cursor">
                <span>学管师</span>
                <i class="el-icon-caret-bottom" />
              </div>
            </el-popover>
            <el-popover width="150" trigger="click">
              <el-radio-group
                @change="TeachingChange"
                v-model="query.curr_type"
              >
                <el-radio class="padding-xs" :label="-1">全部</el-radio>
                <el-radio class="padding-xs" :label="0">班级</el-radio>
                <el-radio class="padding-xs" :label="1">一对一</el-radio>
              </el-radio-group>
              <div slot="reference" class="cursor">
                <span>授课模式</span>
                <i class="el-icon-caret-bottom" />
              </div>
            </el-popover>
            <el-popover width="250" trigger="click">
              <el-radio-group @change="ChargingChange" v-model="ChargingSelect">
                <el-radio class="padding-xs" label="1">全部</el-radio>
                <el-radio class="padding-xs" label="2"
                  >按课时 (剩余课时)</el-radio
                >
                <el-radio class="padding-xs" label="3"
                  >按期 (剩余课时)</el-radio
                >
                <el-radio class="padding-xs" label="4"
                  >按时间(到期时间)</el-radio
                >
                <el-radio class="padding-xs" label="5"
                  >按课时 (课时有效时间)</el-radio
                >
              </el-radio-group>
              <div slot="reference" class="cursor">
                <span>收费模式</span>
                <i class="el-icon-caret-bottom" />
              </div>
            </el-popover>
            <!-- <div v-if="!query.curriculum_id.length" slot="reference">
              <i class="el-icon-link text-grey" />
              <span class="text-grey">剩余情况</span>
              <i class="el-icon-caret-bottom text-grey" />
            </div>
            <el-popover v-else width="350" trigger="click">
              <el-input v-model="classInput" placeholder="" size="mini" />
              <el-checkbox-group
                @change="Curriculum_change"
                v-model="Curriculum_name"
              >
                <el-checkbox
                  class="padding-xs"
                  :label="item.curriculum_name"
                  v-for="(item, index) of CurriculumList"
                  :key="index"
                />
              </el-checkbox-group>
              <div slot="reference" class="cursor">
                <i class="el-icon-link text-grey" />
                <span>剩余情况</span>
                <i class="el-icon-caret-bottom" />
              </div>
            </el-popover> -->
            <el-popover width="150" trigger="click">
              <el-checkbox-group
                @change="Enrollment_change"
                v-model="Enrollment_name"
              >
                <el-checkbox
                  class="padding-xs"
                  :label="item.Name"
                  v-for="(item, index) of Enrollment_Status"
                  :key="index"
                />
              </el-checkbox-group>
              <div slot="reference" class="cursor">
                <span>报读状态</span>
                <i class="el-icon-caret-bottom" />
              </div>
            </el-popover>
            <el-popover width="150" trigger="click">
              <el-radio-group @change="division_change" v-model="is_division">
                <el-radio class="padding-xs" label="是" />
                <el-radio class="padding-xs" label="否" />
              </el-radio-group>
              <div slot="reference" class="cursor">
                <span>是否分班</span>
                <i class="el-icon-caret-bottom" />
                <span class="border-right" />
              </div>
            </el-popover>
            <el-popover width="150" trigger="click">
              <el-radio-group @change="arrears_change" v-model="is_arrears">
                <el-radio class="padding-xs" label="是" />
                <el-radio class="padding-xs" label="否" />
              </el-radio-group>
              <div slot="reference" class="cursor">
                <span>是否欠费</span>
                <i class="el-icon-caret-bottom" />
                <span class="border-right" />
              </div>
            </el-popover>

            <el-popover width="370" trigger="click">
              <el-date-picker
                ref="datePicker"
                v-model="date"
                type="daterange"
                @change="date_change"
                format="yyyy 年 MM 月 dd 日"
                value-format="yyyy-MM-dd"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                size="mini"
              />
              <div slot="reference" class="cursor">
                <span>报名日期</span>
                <i class="el-icon-caret-bottom" />
                <span class="border-right" />
              </div>
            </el-popover>

            <el-popover width="150" trigger="click">
              <el-radio-group
                @change="old_student_change"
                v-model="is_old_student"
              >
                <el-radio class="padding-xs" label="新生" />
                <el-radio class="padding-xs" label="老生" />
              </el-radio-group>
              <div slot="reference" class="cursor">
                <span>新老生</span>
                <i class="el-icon-caret-bottom" />
                <span class="border-right" />
              </div>
            </el-popover>
          </div>
          <div class="work-title-search" style="width: 30%">
            <el-input
              placeholder="姓名，手机号，微信号"
              v-model="KeyWord"
              class="input-with-select"
              size="mini"
              style="width: 66%"
            >
              <el-button
                slot="append"
                @click="select_Student"
                icon="el-icon-search"
              />
            </el-input>
            <span class="text-blue margin-left-sm cursor" @click="del_selsect"
              >清空筛选</span
            >
          </div>
        </div>
        <div
          class="work-title-status flex aligin-items-center justify-content-between flex-wrap-wrap margin-top-sm"
        >
          <div class="flex aligin-items-center flex-wrap-wrap">
            <div
              v-if="school_name.length"
              class="text-gray flex aligin-items-center margin-left-lg margin-top-sm"
            >
              <div>报读校区：</div>
              <div
                v-for="(btn, btnIndex) in school_name"
                :key="btnIndex"
                :class="{ 'margin-left': btnIndex > 0 }"
              >
                <el-button size="mini">
                  {{ btn }}
                  <i
                    class="el-icon-close"
                    @click="del_Choose_Campus(btnIndex, 'school_name')"
                  />
                </el-button>
              </div>
            </div>
            <div
              v-if="School_admin_Enrolling.length"
              class="text-gray flex aligin-items-center margin-left-lg margin-top-sm"
            >
              <div>学管师：</div>
              <div
                v-for="(btn, btnIndex) in School_admin_Enrolling"
                :key="btnIndex"
                :class="{ 'margin-left': btnIndex > 0 }"
              >
                <el-button size="mini">
                  {{ btn }}
                  <i
                    class="el-icon-close"
                    @click="del_School_admin_Enrolling(btnIndex)"
                  />
                </el-button>
              </div>
            </div>
            <div
              v-if="courseClass_name.length"
              class="text-gray flex aligin-items-center margin-left-lg margin-top-sm"
            >
              <div>课程类别：</div>
              <div>
                <el-button
                  size="mini"
                  v-for="(btn, btnIndex) in courseClass_name"
                  :key="btnIndex"
                  :class="{ 'margin-left': btnIndex > 0 }"
                >
                  {{ btn }}
                  <i
                    class="el-icon-close"
                    @click="del_Choose_Campus(btnIndex, 'courseClass_name')"
                  />
                </el-button>
              </div>
            </div>
            <div
              v-if="Curriculum_name.length"
              class="text-gray flex aligin-items-center margin-left-lg margin-top-sm"
            >
              <div>课程：</div>
              <div>
                <el-button
                  size="mini"
                  v-for="(btn, btnIndex) in Curriculum_name"
                  :key="btnIndex"
                  :class="{ 'margin-left': btnIndex > 0 }"
                >
                  {{ btn }}
                  <i
                    class="el-icon-close"
                    @click="del_Choose_Campus(btnIndex, 'Curriculum_name')"
                  />
                </el-button>
              </div>
            </div>
            <div
              v-if="displayTeaching"
              class="text-gray flex aligin-items-center margin-left-lg margin-top-sm"
            >
              <div>授课模式：</div>
              <div>
                <el-button size="mini">
                  {{ displayTeaching }}
                  <i
                    class="el-icon-close"
                    @click="del_Choose_Campus('btnIndex', 'displayTeaching')"
                  />
                </el-button>
              </div>
            </div>
            <div
              v-if="displayCharging"
              class="text-gray flex aligin-items-center margin-left-lg margin-top-sm"
            >
              <div>收费模式：</div>
              <div>
                <el-button size="mini">
                  {{ displayCharging }}
                  <i
                    class="el-icon-close"
                    @click="del_Choose_Campus(btnIndex, 'displayCharging')"
                  />
                </el-button>
              </div>
            </div>
            <div
              v-if="Enrollment_name.length"
              class="text-gray flex aligin-items-center margin-left-lg margin-top-sm"
            >
              <div>报读状态：</div>
              <div
                v-for="(btn, btnIndex) in Enrollment_name"
                :key="btnIndex"
                :class="{ 'margin-left': btnIndex > 0 }"
              >
                <el-button size="mini">
                  {{ btn }}
                  <i
                    class="el-icon-close"
                    @click="del_Choose_Campus(btnIndex, 'Enrollment_name')"
                  />
                </el-button>
              </div>
            </div>
            <div
              v-if="is_division"
              class="text-gray flex aligin-items-center margin-left-lg margin-top-sm"
            >
              <div>是否分班：</div>
              <div>
                <el-button size="mini">
                  {{ is_division }}
                  <i
                    class="el-icon-close"
                    @click="del_Choose_Campus('btnIndex', 'is_division')"
                  />
                </el-button>
              </div>
            </div>
            <div
              v-if="is_arrears"
              class="text-gray flex aligin-items-center margin-left-lg margin-top-sm"
            >
              <div>是否欠费：</div>
              <div>
                <el-button size="mini">
                  {{ is_arrears }}
                  <i
                    class="el-icon-close"
                    @click="del_Choose_Campus('btnIndex', 'is_arrears')"
                  />
                </el-button>
              </div>
            </div>

            <div
              v-if="query.registration_time"
              class="text-gray flex aligin-items-center margin-left-lg margin-top-sm"
            >
              <div>报读日期：</div>
              <div>
                <el-button size="mini">
                  {{ this.query.registration_time }}
                  <i
                    class="el-icon-close"
                    @click="del_Choose_Campus('btnIndex', 'registration_time')"
                  />
                </el-button>
              </div>
            </div>
            <div
              v-if="is_old_student"
              class="text-gray flex aligin-items-center margin-left-lg margin-top-sm"
            >
              <div>新老生：</div>
              <div>
                <el-button size="mini">
                  {{ is_old_student }}
                  <i
                    class="el-icon-close"
                    @click="del_Choose_Campus('btnIndex', 'is_old_student')"
                  />
                </el-button>
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-content-between margin-top-xl">
          <div class="flex flex-wrap-wrap">
            <!-- <el-button> 导出当前结果</el-button>
            <el-button> 发送通知公告</el-button>
            <el-button> 批量调整</el-button>
            <el-button> 批量打印二维码</el-button> -->
          </div>
          <el-popover width="200" trigger="click">
            <el-checkbox-group
              v-model="BatchAdjustment"
              v-for="(item, index) in BatchAdjustmentList"
              :key="index"
            >
              <el-checkbox class="padding-xs" :label="item.key">{{
                item.name
              }}</el-checkbox>
            </el-checkbox-group>
            <div slot="reference">
              <el-button type="primary" size="mini">自定义显示列</el-button>
            </div>
          </el-popover>
        </div>
        <div class="myClient-text flex justify-content-between myClient-top-10">
          <div>
            当前结果：学员共计{{ rosterSize }}名，欠费共计{{ arrearMoney }}元
          </div>
        </div>
      </div>
      <div style="margin-top: 10px">
        <as-list
          :ajaxMethods="{ list: 'QueryOrderCurriculumns' }"
          :listQueryProp="query"
          @requestChange="requestChange"
        >
          <el-table-column
            width="150"
            fixed="left"
            align="center"
            label="学员名字"
          >
            <template slot-scope="scope">
              <router-link
                replace
                :to="{
                  path: '/educationCenter/studentInformation',
                  query: { id: scope.row.student_info.id },
                }"
              >
                <span class="cursor text-blue">
                  {{ scope.row.student_info.FullName }}</span
                >
              </router-link>
            </template>
          </el-table-column>
          <el-table-column
            height="40"
            fixed="left"
            width="60"
            align="center"
            prop="student_info.relation_name"
            label="关系"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.student_info.relation_name }}</span>
            </template>
          </el-table-column>
          <template v-for="(item, index) in columnslistComputed">
            <el-table-column
              v-if="['used_hours', 'over_hours'].includes(item.key)"
              align="center"
              :key="index"
              sortable
              width="150"
              height="40"
              :prop="item.key"
              :label="item.name"
            >
            </el-table-column>
            <el-table-column
              v-else
              align="center"
              :key="index"
              height="40"
              :prop="item.key"
              :label="item.name"
            >
            </el-table-column>
          </template>

          <el-table-column
            align="center"
            prop="status"
            label="操作"
            height="40"
          >
            <template slot-scope="scope">
              <el-dropdown trigger="hover" @command="handleCommand">
                <label>
                  操作<i class="el-icon-arrow-down el-icon-caret-bottom"></i>
                </label>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    :command="{ type: 'StopCourse', data: scope.row }"
                  >
                    <span>停课</span>
                  </el-dropdown-item>
                  <!-- <el-dropdown-item>续费</el-dropdown-item> -->
                  <el-dropdown-item
                    :command="{ type: 'ClassEnding', data: scope.row }"
                  >
                    <span>结课</span>
                  </el-dropdown-item>
                  <!-- <el-dropdown-item>转课</el-dropdown-item>  -->
                </el-dropdown-menu>
              </el-dropdown>
            </template></el-table-column
          >
        </as-list>
      </div>
    </div>
    <NewProject
      :Visible="editVisible && editType === 1"
      @closepop="closeEditPop"
    ></NewProject>
    <newExam
      :Visible="editVisible && editType === 2"
      @closepop="closeEditPop"
    ></newExam>
    <BatchAdjustment
      ref="BatchAdjustmentList"
      :Adjustment="BatchAdjustmentList"
      :Visible="editVisible && editType === 3"
      @closepop="closeEditPop"
    ></BatchAdjustment>

    <!-- //接 -->

    <StudentsDivision
      ref="BatchAdjustmentList"
      :classesId="classesId"
      :Visible="editDivision"
      @closepop="closeEditPop"
    ></StudentsDivision>
    <StopCourse
      :Visible="editVisible"
      :StopCourse_data="StopCourse_data"
      :ClassEnding="editType === 'ClassEnding'"
      @closepop="closeEditPop"
      :title="titlename"
    ></StopCourse>
  </div>
</template>
<script>
import AsList from "@/components/as-list";
import StudentsDivision from "../../components/StudentsDivision";
import StopCourse from "../../components/StopCourse";
import NewProject from "../../components/newProject";
import BatchAdjustment from "../../components/BatchAdjustment";
import newExam from "../../components/newExam";
export default {
  components: {
    AsList,
    NewProject,
    newExam,
    BatchAdjustment,
    StopCourse,
    StudentsDivision,
  },
  data() {
    return {
      classesId: 0,
      editType: "",
      StopCourse_data: {},
      titlename: "",
      editDivision: false,
      isClassEnding: false,
      school_name1: "",
      KeyWord: "",
      is_old_student: "", //是否新生
      is_division: "", //是否分班显示
      is_arrears: "", //是否欠费显示
      Enrollment_name: [], //状态显示
      school_name: [], //学校显示
      arrearMoney: "0", //总计欠费
      rosterSize: "0", //返回的学员人数
      Enrollment_Status: [], //报读状态
      displayCharging: "", //显示收费
      ChargingSelect: "1", //选中收费
      displayTeaching: "", //显示授课模式
      datamodel: {},
      Curriculum_name: [],
      CurriculumList: [], //课程列表
      courseClass_name: [], //选择课程类别
      courseClassList: [], //课程类别
      //获取数据字典
      listQuery: {
        HasRole: false,
        module: "",
        TotalCount: 0,
        PageIndex: 1,
        PageSize: 1000000,
      },
      query: {
        school_id: [], //校区
        admin_school: [], //学管师
        pay_mode: [],
        curr_type: -1, //授课模式
        student_status: [], //报读状态
        is_division: null, //是否分班
        is_arrears: null, //是否欠费
        is_old_student: null, //是否老生
        registration_time: "", //报名时间 ~ 隔开 2020-0101~2021-0101 ,
        curr_cate_type: [], //课程类别id
        curr_id: [], //课程
        KeyWord: "",
        PageIndex: 1,
        PageSize: 10,
      },
      School_admin1: "",
      relationship: ["母亲", "父亲", "本人", "其他"],
      offsetHeight: 0,
      editVisible: false,
      BatchAdjustment: [
        "status_name",
        "student_info.phone",
        "student_info.school_admin_name",
        "curriculum.curriculum_name",
        "curriculum.tmode",
        "school_admin_name",
      ],
      BatchAdjustmentList: [
        { name: "联系方式", key: "student_info.phone" },
        { name: "学管师", key: "student_info.school_admin_name" },
        { name: "报读课程", key: "curriculum.curriculum_name" },
        { name: "授课模式", key: "curriculum.tmode" },
        { name: "购买数量", key: "pay_num" },
        { name: "已用数量", key: "used_hours" },
        { name: "剩余数量", key: "over_hours" },
        { name: "总学费", key: "total_money" },
        { name: "实缴费用", key: "pay_money" },
        { name: "已用费用", key: "used_money" },
        { name: "报读学校", key: "student_info.school_name" },
        { name: "状态", key: "status_name" },
      ],
      //报读列表
      EnrollmentList: [],
      school_list: [], //校区
      CourseClass: [], //课程大类
      ApplyForCampus: [], //报读校区
      School_admin_id_Enrolling: [], //学管师——id_报读
      School_admin_Enrolling: [], //学管师——报读
      getIntroducer_PageIndex_Enrolling: 1, //学管师-分页
      //报读列表结束
      isShow: false,
      classInput: "",
      schoolInput: "",
      date: "",
      humanFace: "", //是否录入人脸
      Arrears: "", //是否欠费
      NewOldStudents: "", //新老生
      schoolList: "",
      schoolManagerList: [],
      schoolManager: "",
      stuInput: "", // 学生姓名查询
      stuSelect: "",
      NewOldStudentsList: [
        {
          label: "老生",
          value: 1,
        },
        {
          label: "新生",
          value: 2,
        },
      ],

      monthList: [],
      total: "",
      datalist: [],
    };
  },
  watch: {
    Visible(val) {
      this.dialogVisible = val;
    },
    editVisible(val) {
      if (!val) {
        this.query = JSON.parse(JSON.stringify(this.query));
      }
    },
  },
  computed: {
    columnslistComputed() {
      return this.BatchAdjustmentList.filter(
        (t) => this.BatchAdjustment.findIndex((t1) => t1 == t.key) > -1
      );
    },
  },

  created() {
    this.gteEnrollmentList();
    this.get_NeedInformation();
  },
  methods: {
    handleCommand(val) {
      // console.log(val.type,val.data);
      this.editHandle(val.type, val.data);
    },
    editHandle(type, id) {
      if (type == "StopCourse") {
        this.titlename = "停课";
        this.editVisible = true;
        this.editType = type;
        this.StopCourse_data = id;
      } else if (type == "ClassEnding") {
        this.titlename = "结课";
        this.editVisible = true;
        this.editType = type;
        this.isClassEnding = true;
        this.StopCourse_data = id;
      }
    },
    //报读日期
    date_change(e) {
      if (this.date.length)
        this.query.registration_time = this.date[0] + "~" + this.date[1];
    },
    //是否新生is_old_student
    old_student_change(e) {
      if (e == "新生") {
        this.query.is_old_student = true;
      } else if (e == "老生") {
        this.query.is_old_student = false;
      } else {
        this.query.is_old_student = null;
      }
    },
    //是否分班
    division_change(e) {
      if (e == "是") {
        this.query.is_division = true;
      } else if (e == "否") {
        this.query.is_division = false;
      } else {
        this.query.is_division = null;
      }
    },
    //是否欠费
    arrears_change(e) {
      if (e == "是") {
        this.query.is_arrears = true;
      } else if (e == "否") {
        this.query.is_arrears = false;
      } else {
        this.query.is_arrears = null;
      }
    },
    //清空筛选
    del_selsect() {
      this.displayCharging = ""; //收费模式
      this.displayTeaching = ""; //授课模式
      this.School_admin_Enrolling = []; //学管师
      this.courseClass_name = []; //选择课程类别
      this.Curriculum_name = [];
      this.is_old_student = ""; //是否新生
      this.is_division = ""; //是否分班显示
      this.is_arrears = ""; //是否欠费显示
      this.Enrollment_name = []; //状态显示
      this.school_name = []; //学校显示
      this.date = []; //报读日期显示
      this.query = {
        school_id: [], //校区
        admin_school: [], //学管师
        pay_mode: [],
        curr_type: -1, //授课模式
        student_status: [], //报读状态
        is_division: null, //是否分班
        is_arrears: null, //是否欠费
        is_old_student: null, //是否老生
        registration_time: "", //报名时间 ~ 隔开 2020-0101~2021-0101 ,
        curr_cate_type: [], //课程类别id
        curr_id: [], //课程
        KeyWord: "",
        PageIndex: 1,
        PageSize: 10,
      };
    },
    //搜索
    select_Student() {
      this.query.KeyWord = this.KeyWord;
    },

    //状态
    Enrollment_change(e) {
      var Enrollment_Status = this.Enrollment_Status.filter((rs) => {
        return this.Enrollment_name.indexOf(rs.Name) != -1;
      });
      this.query.student_status = Enrollment_Status.map((rs) => {
        return rs.Key;
      });
    },
    //校区
    school_change(e) {
      var school_id = this.school_list.filter((rs) => {
        return this.school_name.indexOf(rs.name) != -1;
      });
      this.query.school_id = school_id.map((rs) => {
        return rs.id;
      });
    },
    //as-list接口请求返回
    requestChange(res) {
      this.rosterSize = res.total;
      if (res.outer_data) {
        this.arrearMoney = res.outer_data.arrearMoney;
      }
    },
    //收费模式
    ChargingChange() {
      if (this.ChargingSelect == "2") {
        this.displayCharging = "按课时 (剩余课时)";
      } else if (this.ChargingSelect == "3") {
        this.displayCharging = "按期 (剩余课时)";
      } else if (this.ChargingSelect == "4") {
        this.displayCharging = "按时间(到期时间)";
      } else if (this.ChargingSelect == "5") {
        this.displayCharging = "按课时 (课时有效时间)";
      } else {
        this.displayCharging = "";
      }
    },
    //授课模式
    TeachingChange(e) {
      if (this.query.curr_type == 1) {
        this.displayTeaching = "一对一";
      } else if (this.query.curr_type == -1) {
        this.displayTeaching = "";
      } else {
        this.displayTeaching = "班课";
      }
    },
    Choose_Campus_change() {
      for (let index = 0; index < this.school_list.length; index++) {
        if (this.school_list[index].id == this.Classroom_QueryData.shoolid) {
          this.Choose_Campus_name = this.school_list[index].name;
        }
      }
    },

    get_NeedInformation() {
      //查询校区
      var model = {
        type: 1,
        PageIndex: 1,
        PageSize: 10000,
        Sort: [
          {
            Field: "id",
            Type: 0,
          },
        ],
      };
      this.API.OrganizationList(model).then((res) => {
        this.school_list = res.data.rows;
      });
      // 获取课程类别
      this.listQuery.module = "course_class";
      this.$Dictionary.GetDictionarys(this.listQuery).then((res) => {
        this.courseClassList = res.data.rows;
        this.courseClassList = this.courseClassList.filter((x) => {
          return x.parent_id != 0;
        });
        this.$forceUpdate();
      });
      // this.$Dictionary
      //   .GetDictionaryAll(this.$Dictionary.moduleConfig.EnrollmentStatus)
      //   .then((res) => {
      //     this.Enrollment_Status = res.data.rows;
      //   });
      this.API.SystemGetSettings("OrderCurriculumnsStatus", false).then(
        (res) => {
          this.Enrollment_Status = res;
        }
      );
    },
    //选择课程
    Curriculum_change(e) {
      // CurriculumList
      var CurriculumList = this.CurriculumList.filter((rs) => {
        return this.Curriculum_name.indexOf(rs.curriculum_name) != -1;
      });
      this.query.curr_id = CurriculumList.map((rs) => {
        return rs.id;
      });
    },
    //选择课程类别
    courseClass_change() {
      var courseClass_list = this.courseClassList.filter((rs) => {
        return this.courseClass_name.indexOf(rs.title) != -1;
      });
      this.query.curr_cate_type = courseClass_list.map((rs) => {
        return rs.id;
      });
      this.getCurriculumList(); //课程类别下的课程
    },
    //删除校区搜索
    del_Choose_Campus(index, del) {
      if (del == "school_name") {
        //清除校区
        this.school_name.splice(index, 1);
        var school_id = this.school_list.filter((rs) => {
          return this.school_name.indexOf(rs.name) != -1;
        });
        this.query.school_id = school_id.map((rs) => {
          return rs.id;
        });
        // this.query.school_id = [];
      } else if (del == "courseClass_name") {
        //清除课程类别
        this.courseClass_name.splice(index, 1);
        var courseClassList = this.courseClassList.filter((rs) => {
          return this.courseClass_name.indexOf(rs.title) != -1;
        });
        this.query.curr_cate_type = courseClassList.map((rs) => {
          return rs.id;
        });
        this.getCurriculumList(); //获取课程类别下的课程
      } else if (del == "Enrollment_name") {
        //清除学员报读状态
        this.Enrollment_name.splice(index, 1);
        var Enrollment_name = this.Enrollment_Status.filter((rs) => {
          return this.Enrollment_name.indexOf(rs.Name) != -1;
        });
        this.query.student_status = Enrollment_name.map((rs) => {
          return rs.Key;
        });
        this.getCurriculumList(); //获取课程类别下的课程
      } else if (del == "Curriculum_name") {
        //清除课程
        this.Curriculum_name.splice(index, 1);
        var Curriculum_name = this.CurriculumList.filter((rs) => {
          return this.Curriculum_name.indexOf(rs.curriculum_name) != -1;
        });
        this.query.curr_id = Curriculum_name.map((rs) => {
          return rs.id;
        });
      } else if (del == "displayTeaching") {
        this.displayTeaching = "";
        this.query.curr_type = -1;
      } else if (del == "displayCharging") {
        this.displayCharging = "";
        this.ChargingSelect = "1";
      } else if (del == "is_division") {
        //是否分班
        this.is_division = "";
        this.query.is_division = null;
      } else if (del == "is_arrears") {
        //是否欠费
        this.is_arrears = "";
        this.query.is_arrears = null;
      } else if (del == "is_old_student") {
        //新老生
        this.is_old_student = "";
        this.query.is_old_student = null;
      } else if (del == "registration_time") {
        //报读日期
        this.query.registration_time = "";
        this.date = [];
      }
    },
    getIntroducer(keyword) {
      var model = {
        post_tag: ["学管"],
        PageIndex: this.getIntroducer_PageIndex,
        PageSize: this.getIntroducer_PageSize,
        KeyWord: keyword,
      };
      return this.API.accountQuery(model);
    },
    querySearch(queryString, cb) {
      this.getIntroducer_PageIndex = 1;
      this.getIntroducer(queryString).then((res) => {
        let list = res.data.rows;

        cb(res.data.rows || []);
      });
    },
    getCurriculumList() {
      var model = {
        // tmode: 0,
        // open_school: this.Classroom_QueryData.shoolid,
        curriculum_type: this.query.curr_cate_type,
        KeyWord: "",
        // TotalCount: 0,
        PageIndex: 1,
        PageSize: 15,
      };
      this.API.getCurriculumList(model).then((res) => {
        this.CurriculumList = res.data.rows || [];
        // this.queryResult = Object.assign({}, this.queryResult, res.data);
      });
    },
    //报读列表
    //获取报读列表筛选数据字典
    gteEnrollmentList() {
      //获取学员报读状态
      this.$Dictionary
        .GetDictionaryAll(this.$Dictionary.moduleConfig.EnrollmentStatus)
        .then((res) => {
          this.EnrollmentStatus = res.data.rows;
        });
      //获取学员报读状态
      this.$Dictionary
        .GetDictionaryAll(this.$Dictionary.moduleConfig.ApplyForCampus)
        .then((res) => {
          this.ApplyForCampus = res.data.rows;
        });
      //获取课程大类
      this.$Dictionary
        .GetDictionaryAll(this.$Dictionary.moduleConfig.CourseClass)
        .then((res) => {
          this.CourseClass = res.data.rows;
        });
    },
    //点击某一项的事件 学管师
    selectChange_Enrolling(item) {
      var _true = false;
      if (
        this.School_admin_id_Enrolling &&
        this.School_admin_id_Enrolling.length
      ) {
        for (
          let index = 0;
          index < this.School_admin_id_Enrolling.length;
          index++
        ) {
          if (this.School_admin_id_Enrolling[index] == item.id) {
            _true = true;
          } else {
            _true = false;
          }
        }
        if (!_true) {
          this.School_admin_Enrolling.push(item.full_name);
          this.School_admin_id_Enrolling.push(item.id);
          this.query.admin_school = this.School_admin_id_Enrolling;
        }
      } else {
        this.School_admin_Enrolling.push(item.full_name);
        this.School_admin_id_Enrolling.push(item.id);
        this.query.admin_school = this.School_admin_id_Enrolling;
      }
    },
    loadMoreStudent_Enrolling(queryString, cb) {
      this.getIntroducer_PageIndex_Enrolling++;
      this.getIntroducer(queryString).then((res) => {
        let list = res.data.rows;
        cb(list);
      });
    },
    //删除学管师
    del_School_admin_Enrolling(index) {
      this.School_admin_Enrolling.splice(index, 1);
      this.School_admin_id_Enrolling.splice(index, 1);
      this.query.admin_school = this.School_admin_id_Enrolling;
    },

    //报读列表结束

    JumpUrl(item) {
      this.$router.push("/educationCenter/studentInformation?id=" + item.id);
    },
    starStatus(index) {
      this.starS.splice(index, 1);
    },

    // openDate(t) {
    //   if (t) {
    //     this.$refs.evaluation.pickerVisible = true;
    //   } else {
    //     this.$refs.datePicker.pickerVisible = true;
    //   }
    // },
    // classopenDate(t) {
    //   this.$refs.classdatePicker.pickerVisible = true;
    // },
    handleClick(row) {},
    // editHandle(type) {
    //   if (type == 3) {
    //     if (this.BatchAdjustmentList && this.BatchAdjustmentList.length) {
    //       this.editVisible = true;
    //       this.editType = type;
    //     } else {
    //       this.$message.error("请先勾选需要调整的学员！");
    //     }
    //   } else {
    //     this.editVisible = true;
    //     this.editType = type;
    //   }
    // },
    goNav(url) {
      this.$router.push(url);
    },
  },
};
</script>

<style  scoped>
>>> el-table-column {
  height: 40px;
}
.color-box {
  color: #0081ff;
  border: 1px solid #8cc5ff;
  background-color: #e8f5ff;
}
.work-title-content div {
  padding: 0 5px 0 0px;
}
.total {
  width: 100%;
}

.shool {
  margin: 0px 10px;
}

.configure {
  width: 100%;
  background-color: #fff;
  padding: 10px;
}

>>> .el-select .el-input {
  width: 110px !important;
}

.configure {
  width: 100%;
  background-color: #fff;
  padding: 10px;
}

.el-select .el-input {
  width: 105px;
}
</style>
