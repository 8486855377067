<template>
  <el-dialog
    title="批量调整"
    :visible.sync="dialogVisible"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @closed="close"
    width="45%"
  >
    <div style="height: 300px">
      <el-alert
        :title="
          '共有' + datalist.length + '条记录将被批量调整，全部保存后无法撤销'
        "
        type="warning"
        :closable="false"
        show-icon
      >
      </el-alert>
      <div class="width-100 margin-auto" style="">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <!-- <el-form-item label="科目名称" prop="title">
          <el-input
            v-model="ruleForm.title"
            placeholder="请填写科目名称,最多50个字,不能为特殊符号"
          ></el-input>
        </el-form-item> -->
          <el-form-item label="调整内容" prop="AdjustContent">
            <el-select
              style="width: 80%"
              @change="AdjustContent_"
              v-model="ruleForm.AdjustContent"
              placeholder="请选择调整内容"
            >
              <el-option
                v-for="(item, index) in AdjustContentList"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="统一调整为" prop="UnifiedAdjustment">
            <el-select
              style="width: 80%"
              v-model="ruleForm.UnifiedAdjustment"
              placeholder="请选择"
            >
              <el-option
                v-for="(item, index) in UnifiedAdjustment_List"
                :key="index"
                :label="item.title"
                :value="item.Key"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submit('ruleForm')"
              >确定</el-button
            >
            <el-button class="myClient-left-30" @click="close">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { Model } from 'echarts';
export default {
  props: {
    Visible: {
      type: Boolean,
      default: false,
    },
    Adjustment: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      PublicSchools: [], //公立学校
      Channel: [], //来源渠道
      Nation: [], //民族
      gradeList: [], //年级
      IndustryList: [], //行业
      UnifiedAdjustment_List: [],
      ruleForm: {
        AdjustContent: "", //调整内容
        UnifiedAdjustment: "", //统一调整
      },
	  
      AdjustContentList: [
        {
          label: "来源渠道",
          value: "1",
        },
        {
          label: "民族",
          value: "2",
        },
        {
          label: "公立学校",
          value: "3",
        },
        {
          label: "年级",
          value: "4",
        },
        {
          label: "行业",
          value: "5",
        },
      ],
      Industry: [], //行业 
      dialogVisible: false,
      groupNmae: "",
      datalist: [],
      ruleForm: {
        title: "",
      },
      rules: {
        UnifiedAdjustment: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        AdjustContent: [
          { required: true, message: "请选择调整内容", trigger: "change" },
        ],
        // title: [
        //   {
        //     required: true,
        //     message: "请输入项目名称",
        //     trigger: "blur",
        //   },
        //   {
        //     max: 50,
        //     message: "最多50个字",
        //     trigger: "blur",
        //   },
        // ],
      },
    };
  },
  watch: {
    Visible(val) {
      this.dialogVisible = val;
    },
  },
  created() {
    this.dialogVisible = this.Visible;
    this.datalist = this.Adjustment;
    this.initSelectData();
  },
  methods: {
    AdjustContent_() {
      if (this.ruleForm.AdjustContent === "1") {
        this.UnifiedAdjustment_List = this.Channel;
      } else if (this.ruleForm.AdjustContent === "2") {
        this.UnifiedAdjustment_List = this.Nation;
      } else if (this.ruleForm.AdjustContent === "3") {
        this.UnifiedAdjustment_List = this.PublicSchools;
      } else if (this.ruleForm.AdjustContent === "4") {
        this.UnifiedAdjustment_List = this.gradeList;
      } else if (this.ruleForm.AdjustContent === "5") {
        this.UnifiedAdjustment_List = this.IndustryList;
      }
    },
    initSelectData() {
      this.$Dictionary
        .GetDictionaryAll(this.$Dictionary.moduleConfig.Nation)
        .then((res) => {
          this.Nation = res.data.rows;
          // this.Nation = this.Nation.filter((x) => {
          //   return x.parent_id != 0;
          // });
        });
      this.$Dictionary
        .GetDictionaryAll(this.$Dictionary.moduleConfig.Grade)
        .then((res) => {
          this.gradeList = res.data.rows;
        });
      this.$Dictionary
        .GetDictionaryAll(this.$Dictionary.moduleConfig.Industry)
        .then((res) => {
          this.IndustryList = res.data.rows;
          this.IndustryList = this.IndustryList.filter((x) => {
            return x.parent_id != 0;
          });
        });
      this.$Dictionary
        .GetDictionaryAll(this.$Dictionary.moduleConfig.Channel)
        .then((res) => {
          this.Channel = res.data.rows;
          this.Channel = this.Channel.filter((x) => {
            return x.parent_id != 0;
          });
        });
      this.$Dictionary
        .GetDictionaryAll(this.$Dictionary.moduleConfig.PublicSchools)
        .then((res) => {
          this.PublicSchools = res.data.rows;
          this.PublicSchools = this.PublicSchools.filter((x) => {
            return x.parent_id != 0;
          });
        });
    },
    // getGetDictionaryAll() {
    //   this.$Dictionary
    //     .GetDictionaryAll(this.$Dictionary.moduleConfig.Industry)
    //     .then((res) => {
    //       this.IndustryList = res.data.rows;
    //       this.IndustryList = this.IndustryList.filter((x) => {
    //         return x.parent_id != 0;
    //       });
    //     });
    // },
    refsh(data) {
      this.datalist = data;
    },
    close() {
      this.$emit("closepop");
    },
    submit(ruleForm) {
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          alert("submit!");
		  var	model={
			id:[]
		}
		  for (let index = 0; index < this.datalist.length; index++) {
			   model.id.push(this.datalist[index].id) 
		  } 
//   this.API.StudentCreate(this.stuForm).then((res) => {
//           if (res.success) {
//             this.stuDialog = false;
//             this.$message({
//               message: res.message,
//               type: "success",
//             });
//           } else {
//             this.$message.error(res.message);
//           }
//           console.log(res);
//         });



        } else { 
          return false;
        }
      });
    },
  },
};
</script>

<style>
</style>
