<template>
	<el-dialog title="新建考试" :visible.sync="dialogVisible" :append-to-body="true" :close-on-click-modal="false"
		:close-on-press-escape="false" @closed="close" width="25%">
		<div style="height: 110px;">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="考试名称" prop="name">
					<el-input v-model="ruleForm.name" placeholder="请填写考试名称,最多15个字"></el-input>
				</el-form-item>
			</el-form>
			<div class="flex justify-content-flexEnd">
				<el-button type="primary">确定</el-button>
				<el-button class="myClient-left-30" @click="close">取消</el-button>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	export default {
		props: {
			Visible: {
				type: Boolean,
				default: false,
			}
		},
		data() {
			return {
				dialogVisible: false,
				groupNmae: '',
				ruleForm: {
					name: ''
				},
				rules:{
					name:[
						{
							required: true,
							message: '请输入考试名称',
							trigger: 'blur'
						}, {
							max: 15,
							message: '最多15个字',
							trigger: 'blur'
						}
					]
				}
			}
		},
		watch: {
			Visible(val) {
				this.dialogVisible = val;
			}
		},
		created() {
			this.dialogVisible = this.Visible;
		},
		methods: {
			close() {
				this.$emit('closepop')
			},

		}
	}
</script>

<style>
</style>
