<template>
  <div class="total">
    <div class="work-title bg-white flex justify-content-center">
      <el-button
        round
        @click="colorChange(1)"
        :class="color1 ? 'color-box' : ''"
        >成绩管理</el-button
      >
      <el-button
        :class="color2 ? 'color-box' : ''"
        round
        @click="colorChange(2)"
        >项目设置</el-button
      >
      <el-button
        :class="color3 ? 'color-box' : ''"
        round
        @click="colorChange(3)"
        >考试设置</el-button
      >
    </div>
    <div v-show="color1">
      <div class="work-title bg-white margin-top padding">
        <div>
          <el-button
            type="primary"
            size="mini"
            @click="goNav('/educationCenter/enterResults')"
            >录入成绩</el-button
          >
        </div>
        <div
          class="work-title-all flex justify-content-between aligin-items-center flex-wrap-wrap"
        >
          <div class="work-title-content flex aligin-items-center">
            <el-popover width="350" trigger="click">
              <!-- <el-input v-model="classInput" placeholder="" size="mini" /> -->
              <el-checkbox-group v-model="schoolList">
                <el-checkbox
                  class="padding-xs"
                  :label="item.label"
                  v-for="(item, index) of shoolList"
                  :key="index"
                />
              </el-checkbox-group>
              <div slot="reference">
                <span>来源</span>
                <i class="el-icon-caret-bottom" />
                <span class="border-right" />
              </div>
            </el-popover>

            <el-popover width="350" trigger="click">
              <!-- <el-input v-model="classInput" placeholder="" size="mini" /> -->
              <el-checkbox-group v-model="schoolList">
                <el-checkbox
                  class="padding-xs"
                  :label="item.label"
                  v-for="(item, index) of shoolList"
                  :key="index"
                />
              </el-checkbox-group>
              <div slot="reference">
                <span>项目</span>
                <i class="el-icon-caret-bottom" />
                <span class="border-right" />
              </div>
            </el-popover>
            <el-popover width="350" trigger="click">
              <!-- <el-input v-model="classInput" placeholder="" size="mini" /> -->
              <el-checkbox-group v-model="schoolList">
                <el-checkbox
                  class="padding-xs"
                  :label="item.label"
                  v-for="(item, index) of shoolList"
                  :key="index"
                />
              </el-checkbox-group>
              <div slot="reference">
                <span>班级</span>
                <i class="el-icon-caret-bottom" />
                <span class="border-right" />
              </div>
            </el-popover>
            <el-popover width="350" trigger="click">
              <!-- <el-input v-model="classInput" placeholder="" size="mini" /> -->
              <el-checkbox-group v-model="schoolList">
                <el-checkbox
                  class="padding-xs"
                  :label="item.label"
                  v-for="(item, index) of shoolList"
                  :key="index"
                />
              </el-checkbox-group>
              <div slot="reference">
                <span>考试</span>
                <i class="el-icon-caret-bottom" />
                <span class="border-right" />
              </div>
            </el-popover>
            <el-popover width="370" trigger="click">
              <el-date-picker
                ref="datePicker"
                v-model="date"
                type="daterange"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                size="mini"
              />
              <div slot="reference">
                <span>考试时间</span>
                <i class="el-icon-caret-bottom" />
                <span class="border-right" />
              </div>
            </el-popover>
          </div>

          <div class="work-title-search">
            <el-input
              placeholder="请输入姓名,支持拼音缩写"
              v-model="stuInput"
              class="input-with-select"
              size="mini"
            >
              <el-select
                v-model="stuSelect"
                slot="prepend"
                placeholder="学员姓名"
              >
                <el-option label="张龙" value="1" />
                <el-option label="陈欢" value="2" />
                <el-option label="周杰" value="3" />
                <el-option label="朱均" value="3" />
              </el-select>
              <el-button slot="append" icon="el-icon-search" />
            </el-input>
          </div>
        </div>

        <div
          class="work-title-status flex margin-top-sm aligin-items-center justify-content-between"
        >
          <div class="flex aligin-items-center">
            <div class="text-gray flex aligin-items-center">
              <div>考试时间：</div>
              <el-date-picker
                ref="datePicker"
                v-model="date"
                type="daterange"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                size="mini"
              />
            </div>
            <!-- <div class="text-gray flex aligin-items-center  margin-left-lg">
						 				<div>订单状态：</div>
						 				<div v-for="(btn, btnIndex) in orderSatus" :key="btnIndex">
						 					<div :class="{ 'margin-left': btnIndex > 0 }">
						 						<el-button size="mini">
						 							{{ btn }}
						 							<i class="el-icon-close" @click="delOderStatus(btnIndex)" />
						 						</el-button>
						 					</div>
						 				</div>
						 			</div> -->
          </div>

          <div class="text-blue">清空筛选</div>
        </div>
      </div>
      <div class="flex justify-content-between margin-top-xl">
        <div class="flex flex-wrap-wrap">
          <el-button> 导出当前结果</el-button>
          <el-button> 发送通知公告</el-button>
        </div>

        <el-popover width="270" trigger="click">
          <el-checkbox-group v-model="Arrears">
            <el-checkbox
              class="padding-xs"
              :label="item.label"
              v-for="(item, index) of Groupinglist"
              :key="index"
            />
          </el-checkbox-group>
          <div slot="reference">
            <el-button>自定义显示例</el-button>
          </div>
        </el-popover>
      </div>
      <div class="myClient-text flex justify-content-between myClient-top-10">
        <div>当前结果：共计11条</div>
      </div>
      <div style="margin-top: 10px">
        <as-list :initdataList="enrollmentList">
          <el-table-column
            align="center"
            sortable
            prop="StudentName"
            class="cursor"
            @click="JumpUrl('/educationCenter/studentInformation')"
            label="学员名字"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="relationship"
            label="关系"
          ></el-table-column>
          <el-table-column align="center" prop="phone" label="联系方式">
            <template slot-scope="scope" slot="header">
              <span>联系方式</span>
              <el-tooltip
                class="item"
                effect="dark"
                content="联系电话可通过学员应用下的权限点进行全显/隐藏控制"
                placement="top"
              >
                <i class="el-icon-question text-blue" />
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="schoolManager"
            label="项目"
          ></el-table-column>
          <el-table-column align="center" sortable prop="bind" label="成绩">
          </el-table-column>
          <el-table-column align="center" prop="text5" label="来源">
          </el-table-column>
          <el-table-column
            align="center"
            prop="humanFace"
            sortable
            label="考试日期"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="arrears"
            label="考试"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="arrears"
            label="班级名称"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="status"
            label="备注"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="arrears"
            label="操作"
          ></el-table-column>
        </as-list>
      </div>
    </div>
    <div v-show="color2">
      <div
        class="work-title bg-white margin-top padding flex justify-content-between"
      >
        <div>
          <el-button type="primary" size="mini" @click="editHandle(1)"
            >新建项目</el-button
          >
        </div>
        <el-col :span="6">
          <el-input v-model="stuInput" placeholder="请输入项目名称">
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </el-col>
      </div>
      <div class="myClient-text flex justify-content-between myClient-top-10">
        <div>当前结果：共计1条</div>
      </div>
      <div style="margin-top: 10px">
        <as-list :initdataList="ProjectList">
          <el-table-column
            sortable
            prop="ProjectName"
            label="项目"
          ></el-table-column>
          <el-table-column align="center" prop="arrears" label="操作">
            <label class="margin-right-sm" @click="editHandle(1)">编辑</label>
            <label>删除</label>
          </el-table-column>
        </as-list>
      </div>
    </div>
    <div v-show="color3">
      <div
        class="work-title bg-white margin-top padding flex justify-content-between"
      >
        <div>
          <el-button type="primary" size="mini" @click="editHandle(2)"
            >新建考试</el-button
          >
        </div>
        <el-col :span="6">
          <el-input v-model="stuInput" placeholder="请输入考试名称">
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </el-col>
      </div>
      <div class="myClient-text flex justify-content-between myClient-top-10">
        <div>当前结果：共计1条</div>
      </div>
      <div style="margin-top: 10px">
        <as-list :initdataList="enrollmentList">
          <el-table-column
            sortable
            prop="StudentName"
            label="考试"
          ></el-table-column>
          <el-table-column align="center" prop="arrears" label="操作">
            <label class="margin-right-sm" @click="editHandle(2)">编辑</label>
            <label>删除</label>
          </el-table-column>
        </as-list>
      </div>
    </div>
  </div>
</template>
<script>
import AsList from "@/components/as-list";

import NewProject from "../../components/newProject";
import BatchAdjustment from "../../components/BatchAdjustment";
import newExam from "../../components/newExam";
import { course, starList, withWithout } from "@/config/index";
export default {
  components: {
    AsList,
    NewProject,
    newExam,
    BatchAdjustment,
    course,
    starList,
    withWithout,
  },
  data() {
    return {
      query: {
        KeyWord: "",
        PageIndex: 1,
        PageSize: 10,
      },
 ProjectList: [
        {
          ProjectName: "xxx", //项目名称
        },
      ], //项目名称
      classInput: "",
      color1: true,
      color2: false,
      color3: false,
      course,
      date: "",
      stuInput: "", // 学生姓名查询
      stuSelect: "", //搜索类型
      orderSatus: ["待支付", "已完成"], // 订单状态
      Arrears: "", //是否欠费
      enrollmentList: [
        {
          StudentName: "周杰",
          gender: "女",
          relationship: "母亲",
          phone: "13200001231",
          schoolManager: "何姝",
          attention: "",
          bind: "",
          humanFace: "",
          arrears: "10000",
          status: "在读",
          school: "驿都城校区",
        },
      ],
      schoolList: "",
      Groupinglist: [
        {
          label: "教室",
          value: true,
        },
        {
          label: "上课时间",
          value: "2",
        },
        {
          label: "分班日期",
          value: "3",
        },
        {
          label: "是否升期",
          value: "4",
        },
        {
          label: "购买数量",
          value: "5",
        },
        {
          label: "已用数量",
          value: "6",
        },
        {
          label: "剩余数量",
          value: "7",
        },
        {
          label: "总学费",
          value: "8",
        },
        {
          label: "实缴学费",
          value: "9",
        },
        {
          label: "已用学费",
          value: "10",
        },
        {
          label: "剩余学费",
          value: "11",
        },
        {
          label: "欠费",
          value: "12",
        },
      ],
    };
  },
  watch: {
    Visible(val) {
      this.dialogVisible = val;
    },
  },
  computed: {
    columnslistComputed() {
      return this.BatchAdjustmentList.filter(
        (t) => this.BatchAdjustment.findIndex((t1) => t1 == t.key) > -1
      );
    },
  },

  created() {
    var main = document.getElementById("main");
    this.gteEnrollmentList();
  },
  methods: {
       colorChange(type) {
      if (type == 1) {
        this.color1 = true;
        this.color2 = false;
        this.color3 = false;
      } else if (type == 2) {
        this.color2 = true;
        this.color1 = false;
        this.color3 = false;
      } else if (type == 3) {
        this.color2 = false;
        this.color1 = false;
        this.color3 = true;
      }
    },
    getIntroducer(keyword) {
      var model = {
        post_tag:['学管'],
        PageIndex: this.getIntroducer_PageIndex,
        PageSize: this.getIntroducer_PageSize,
        KeyWord: keyword,
      };
      return this.API.accountQuery(model);
    },
    querySearch(queryString, cb) {
      this.getIntroducer_PageIndex = 1;
      this.getIntroducer(queryString).then((res) => {
        let list = res.data.rows;

        cb(res.data.rows || []);
      });
    },
    //报读列表
    //获取报读列表筛选数据字典
    gteEnrollmentList() {
      //获取学员报读状态
      this.$Dictionary
        .GetDictionaryAll(this.$Dictionary.moduleConfig.EnrollmentStatus)
        .then((res) => {
          this.EnrollmentStatus = res.data.rows;
        });
      //获取学员报读状态
      this.$Dictionary
        .GetDictionaryAll(this.$Dictionary.moduleConfig.ApplyForCampus)
        .then((res) => {
          this.ApplyForCampus = res.data.rows;
        });
      //获取课程大类
      this.$Dictionary
        .GetDictionaryAll(this.$Dictionary.moduleConfig.CourseClass)
        .then((res) => {
          this.CourseClass = res.data.rows;
        });
    },
    //点击某一项的事件 学管师
    selectChange_Enrolling(item) {
      var _true = false;
      if (
        this.School_admin_id_Enrolling &&
        this.School_admin_id_Enrolling.length
      ) {
        for (
          let index = 0;
          index < this.School_admin_id_Enrolling.length;
          index++
        ) {
          if (this.School_admin_id_Enrolling[index] == item.id) {
            _true = true;
          } else {
            _true = false;
          }
        }
        if (!_true) {
          this.School_admin_Enrolling.push(item.full_name);
          this.School_admin_id_Enrolling.push(item.id);
          // this.listQueryStudent.school_admin = this.School_admin_id_Enrolling;
        }
      } else {
        this.School_admin_Enrolling.push(item.full_name);
        this.School_admin_id_Enrolling.push(item.id);
        // this.listQueryStudent.school_admin = this.School_admin_id_Enrolling;
      }
    },
    loadMoreStudent_Enrolling(queryString, cb) {
      this.getIntroducer_PageIndex_Enrolling++;
      this.getIntroducer(queryString).then((res) => {
        let list = res.data.rows;
        cb(list);
      });
    },
    //删除学管师
    del_School_admin_Enrolling(index) {
      this.School_admin_Enrolling.splice(index, 1);
      this.School_admin_id_Enrolling.splice(index, 1);
      this.listQueryStudent.school_admin = this.School_admin_id_Enrolling;
    },

    //报读列表结束

    JumpUrl(item) {
      this.$router.push("/educationCenter/studentInformation?id=" + item.id);
    },
    starStatus(index) {
      this.starS.splice(index, 1);
    },

    openDate(t) {
      if (t) {
        this.$refs.evaluation.pickerVisible = true;
      } else {
        this.$refs.datePicker.pickerVisible = true;
      }
    },
    classopenDate(t) {
      this.$refs.classdatePicker.pickerVisible = true;
    },
    handleClick(row) {},
    editHandle(type) {
      if (type == 3) {
        if (this.BatchAdjustmentList && this.BatchAdjustmentList.length) {
          this.editVisible = true;
          this.editType = type;
        } else {
          this.$message.error("请先勾选需要调整的学员！");
        }
      } else {
        this.editVisible = true;
        this.editType = type;
      }
    },
    handleChange(value) {},
    goNav(url) {
      this.$router.push(url);
    },
  },
};
</script>

<style  scoped>
>>> el-table-column {
  height: 40px;
}
.color-box {
  color: #0081ff;
  border: 1px solid #8cc5ff;
  background-color: #e8f5ff;
}
.work-title-content div {
  padding: 0 5px 0 0px;
}
.total {
  width: 100%;
}

.shool {
  margin: 0px 10px;
}

.configure {
  width: 100%;
  background-color: #fff;
  padding: 10px;
}

>>> .el-select .el-input {
  width: 110px !important;
}

.configure {
  width: 100%;
  background-color: #fff;
  padding: 10px;
}

.el-select .el-input {
  width: 105px;
}
</style>
